<template>
  <div class="primary-red-bag">
    <div
      :style="`background:${friendGetList.length > 0 ? 'rgba(255, 59, 59, 0.21)' : ''}`"
      class="primary-red-bag-name"
    >
      <div
        :class="['primary-red-bag-name-item', isNeedTransition ? 'item-transition' : '']"
        :style="`top:-${friendItemTopDistance}rem`"
        v-for="(item, k) in friendGetList"
        :key="k"
      >
        {{ item }}
      </div>
    </div>
    <div class="primary-red-bag-title">你有<span class="primary-red-bag-title-active">50</span>元待领取</div>
    <div class="primary-red-bag-content">
      <div
        id="new-share-landing-get-btn"
        :data-clipboard-text="clipboardText"
        @click="copyDownload('#new-share-landing-get-btn', '立即领取')"
        class="primary-red-bag-content-btn__get"
      >
        立即领取
      </div>
    </div>
    <div class="primary-red-bag-time-warp">
      <div class="primary-red-bag-time-warp-item">{{ countDownTimer.hour | addAero }}</div>
      :
      <div class="primary-red-bag-time-warp-item">{{ countDownTimer.minutes | addAero }}</div>
      :
      <div class="primary-red-bag-time-warp-item">{{ countDownTimer.seconds | addAero }}</div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'PrimaryRedBag',
    props: {
      countDownTimer: {
        type: Object,
        default: () => {},
      },
      copyDownload: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        friendGetList: [],
        friendItemTopDistance: 0,
        isNeedTransition: true,
        reSetScrollFlag: 0,
        scrollTimer: 0,
      };
    },
    computed: {
      ...mapGetters('share/shareLanding', ['inviteCode', 'clipboardText', 'shufflingInfo', 'showMaxReward']),
    },
    created() {
      this.reSetScrollFlag = this.shufflingInfo.length;
      if (this.reSetScrollFlag > 0) {
        this.friendGetList = [...this.shufflingInfo, this.shufflingInfo[0]];
      }
    },
    filters: {
      /**
       * @description 补零
       * @param { Number } num 传入需补零数字
       */
      addAero(num) {
        return num < 10 ? `0${num}` : num;
      },
    },
    beforeMount() {
      if (this.reSetScrollFlag > 0) {
        this.friendItemScroll();
      }
    },
    methods: {
      /**
       * @description 好友获得滚动
       */
      friendItemScroll() {
        setInterval(() => {
          if (this.reSetScrollFlag === this.scrollTimer) {
            // 暂停过渡效果
            this.isNeedTransition = false;
            // 恢复滚动区初始位置
            this.friendItemTopDistance = 0;
            this.scrollTimer = 0;
            // 开启过渡效果
            setTimeout(() => {
              this.isNeedTransition = true;
              this.friendItemTopDistance += 0.5;
              this.scrollTimer++;
            }, 100);
            return;
          }
          // 偏移值递增
          this.friendItemTopDistance += 0.5;
          this.scrollTimer++;
        }, 1500);
      },
    },
  };
</script>

<style scoped lang="less">
  .primary-red-bag {
    background: url('~@/assets/images/share-landing-new/red-bg.png') no-repeat no-repeat;
    background-size: 100% 100%;
    height: 8.6rem;
    width: 100%;
    position: relative;
    top: -0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-title {
      font-size: 0.36rem;
      font-weight: 600;
      color: #b94f10;
      margin-top: 0.2rem;
      &-active {
        font-size: 0.8rem;
        color: #ff2828;
      }
    }
    &-name {
      width: 3.8rem;
      color: #ff2828;
      font-size: 0.24rem;
      font-weight: 600;

      border-radius: 0.25rem;
      margin-top: 1.3rem;
      overflow: hidden;
      height: 0.5rem;
      &-item {
        position: relative;
        text-align: center;
        height: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        &-info {
          max-width: 2rem;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &.item-transition {
          transition: top 1s;
        }
      }
    }
    &-info {
      font-size: 0.28rem;
      font-weight: 600;
      color: #ff4a4a;
      display: flex;
      align-items: center;
      margin-top: 0.3rem;
      &-bag {
        width: 0.24rem;
        height: 0.29rem;
        margin-right: 0.16rem;
      }
    }
    &-time-warp {
      display: flex;
      color: #fff;
      align-items: center;
      font-weight: bold;
      margin-top: 0.2rem;
      font-size: 0.24rem;
      &-item {
        width: 0.46rem;
        height: 0.46rem;
        margin: 0 0.1rem;
        background: linear-gradient(#ffd7b9, #ffb47c);
        font-size: 0.3rem;
        font-weight: bold;
        color: #b94f10;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.06rem;
      }
    }

    &-code {
      display: flex;
      align-items: center;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 0.04rem;
      color: #ffffff;
      padding: 0.04rem 0.1rem;
      margin-top: 0.6rem;
      font-size: 0.24rem;
      &-btn__copy {
        width: 0.6rem;
        height: 0.28rem;
        border-radius: 0.14rem;
        border: 1px solid #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.18rem;
        margin-left: 0.16rem;
      }
    }
    &-content {
      &-btn__get {
        margin-top: 2rem;
        width: 4.56rem;
        height: 0.88rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.44rem;
        background: #d8d8d8 linear-gradient(180deg, #fff5e4 0%, #ffd29e 52%, #ffdea9 100%);
        box-shadow: 0 0.02rem 0.12rem 0 rgba(255, 29, 29, 0.5);
        font-size: 0.4rem;
        font-weight: 600;
        color: #b94f10;
      }
      &-phone {
        margin-top: 0.2rem;
        width: 4.56rem;
        height: 0.88rem;
        display: flex;
        align-items: center;
        border-radius: 0.44rem;
        background-color: #fff;
        padding: 0 0.2rem;
        box-sizing: border-box;
        font-size: 0.32rem;
        overflow: hidden;
        &-input {
          width: 3rem;
        }
        &-img__phone {
          width: 0.44rem;
          height: 0.44rem;
        }
      }
    }
  }
</style>
