<template>
  <div class="share-landing-new">
    <div v-if="userInfo && userInfo.role === 'normal'" class="share-landing-new-name">
      你的好友 {{ userInfo.nickname }}
    </div>
    <img class="share-landing-new-title" src="@/assets/images/double-punch/title-bg.png" alt="欢迎来快音" />
    <div class="share-landing-new-container">
      <transition name="fade">
        <PrimaryRedBag :copyDownload="copyDownload" :countDownTimer="countDownTimer"></PrimaryRedBag>
      </transition>
    </div>
    <div class="share-landing-new-discuss">
      <div class="share-landing-new-discuss-content">
        <div class="share-landing-new-discuss-content-title">
          <img
            class="share-landing-new-discuss-content-title-img__icon"
            src="@/assets/images/share-landing-new/icon.png"
            alt="icon"
          />
          看看大家的评论
          <img
            class="share-landing-new-discuss-content-title-img__icon"
            src="@/assets/images/share-landing-new/icon.png"
            alt="icon"
          />
        </div>
        <div class="share-landing-new-discuss-content-warp" ref="scroll">
          <div
            v-for="(item, k) in discussList"
            :key="k"
            :class="['share-landing-new-discuss-content-warp-item', isNeedTransition ? 'item-transition' : '']"
            :style="`top: -${discussItemTopDistance}rem`"
          >
            <img class="share-landing-new-discuss-content-warp-item-img__author" :src="item.avatar_small" alt="头像" />
            <div :class="['share-landing-new-discuss-content-warp-item-info', (k + 1) % 2 === 0 ? 'ffe0c9' : 'ffdfe2']">
              <label
                :class="[
                  'share-landing-new-discuss-content-warp-item-info-icon',
                  (k + 1) % 2 === 0 ? 'ffe0c9' : 'ffdfe2',
                ]"
              ></label>
              <span class="share-landing-new-discuss-content-warp-item-info-cont">{{ item.text }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="share-landing-new-btn-warp">
      <div
        id="new-download-btn"
        @click="copyDownload('#new-download-btn', '固定button')"
        class="share-landing-new-btn"
        :data-clipboard-text="clipboardText"
      >
        点击下载快音 一起听歌赚钱
      </div>
    </div>
  </div>
</template>

<script>
  import PrimaryRedBag from '@/views/Welfare/Fission/DoublePunch/ShareLandingCom/PrimaryRedBag';
  import { mapGetters } from 'vuex';
  import Clipboard from 'clipboard';
  import API from '@/api';
  import Download from '@/utils/download';
  export default {
    name: 'DoublePunchLanding',
    async asyncData({ store, $http, params }) {
      const {
        user_info,
        invite_code,
        clipboard_txt,
        music_list,
        invite_landing_ab,
        show_max_reward,
        evaluation,
        shuffling_info,
      } = await $http.post(API.GET_INVITE_LANDING_PAGE_INFO, {
        ...params,
      });
      store.commit('share/shareLanding/SET_USER_INFO', user_info);
      store.commit('share/shareLanding/SET_INVITE_CODE', invite_code);
      store.commit('share/shareLanding/SET_CLIPBOARD_TEXT', clipboard_txt);
      store.commit('share/shareLanding/SET_MUSIC_LIST', music_list);
      store.commit('share/shareLanding/SET_SHUFFLING_INFO', shuffling_info);
      store.commit('share/shareLanding/SET_MAX_REWARD', show_max_reward);
      store.commit('share/shareLanding/SET_EVALUATION', evaluation);
      store.commit('share/shareLanding/SET_LANDING_AB', invite_landing_ab);
    },
    components: { PrimaryRedBag },
    props: {},
    data() {
      return {
        discussList: [],
        scrollInterval: null,
        discussItemTopDistance: 0, //评论内容偏移值
        isNeedTransition: true, //是否需要过渡动画
        reSetScrollFlag: 0,
        scrollTimer: 0,
        countDownTimer: {
          hour: 24,
          minutes: 0,
          seconds: 0,
        },
      };
    },
    computed: {
      ...mapGetters('share/shareLanding', ['clipboardText', 'userInfo', 'evaluation']),
    },
    created() {
      // 滚动到列表长度
      this.reSetScrollFlag = this.evaluation.length;
      this.discussList = [...this.evaluation, ...this.evaluation];
    },
    beforeMount() {
      this.discussScrollInterval();

      this.interval = setInterval(() => {
        this.countDown();
      }, 1000);
      this.$on('hook:beforeDestroy', () => {
        clearInterval(this.interval);
      });
    },
    methods: {
      /**
       * @description 评论区滚动效果
       */

      discussScrollInterval() {
        this.scrollInterval = setInterval(() => {
          if (this.scrollTimer === this.reSetScrollFlag) {
            // 暂停过渡效果
            this.isNeedTransition = false;
            // 恢复评论区初始位置
            this.discussItemTopDistance = 0;
            this.scrollTimer = 0;
            // 开启过渡效果
            setTimeout(() => {
              this.isNeedTransition = true;
              this.discussItemTopDistance += 1.1;
              this.scrollTimer++;
            }, 100);
            return;
          }
          // 偏移值递增
          this.discussItemTopDistance += 1.1;
          this.scrollTimer++;
        }, 1500);
        this.$on('hook:beforeDestroy', () => {
          clearInterval(this.scrollInterval);
        });
      },
      /**
       * @description 倒计时
       */

      countDown() {
        --this.countDownTimer.seconds;
        if (this.countDownTimer.seconds < 0) {
          this.countDownTimer.seconds = 59;
          this.countDownTimer.minutes -= 1;
        }
        if (this.countDownTimer.minutes < 0) {
          this.countDownTimer.minutes = 59;
          this.countDownTimer.hour -= 1;
        }
        if (this.countDownTimer.hour < 0) {
          this.countDownTimer.seconds = 0;
          this.countDownTimer.minutes = 0;
          this.countDownTimer.hour = 0;
          clearInterval(this.interval);
        }
      },
      /**
       * @description: 音乐列表下载
       */

      copyDownload(element, element_name) {
        try {
          this.$track({
            page_title: `落地页`,
            element_name,
            remarks: `${this.userInfo.uid};${this.inviteCode};${this.sharePosition}`,
          });

          const jump = () => {
            let url = '';

            if (this.$platform.Android) {
              // if (this.appName === 'kuaiyinyue') {
              //   url = Download.downLoad('kuaiyinyue');
              // } else {
              //   url = Download.downLoad(this.sharePosition, { mode: 'divide' });
              // }
              url = Download.downLoad('yyb');
            } else if (this.$platform.iOS) {
              url = Download.downLoad('yyb');
            } else {
              // url = Download.downLoad('fission', { mode: 'divide' });
              url = Download.downLoad('yyb');
            }

            window.location.href = url;
          };
          const clipboard = new Clipboard(element);

          this.$toast({
            message: '亲~正在为你拉起下载页',
            duration: 5000,
            overlay: false,
          });
          // 使用友盟U-link
          // if (process.env.NODE_ENV !== 'development' && this.umLink.isUmLinkInitSuccess) {
          //   return;
          // }
          clipboard.on('success', (e) => {
            e.clearSelection();
            jump();
          });
          clipboard.on('error', () => {
            jump();
          });
        } catch (e) {
          const { stack } = e;
          this.$logger.error({ error: e, message: stack, stack });
        }
      },
    },
  };
</script>

<style scoped lang="less">
  .share-landing-new {
    background: url('~@/assets/images/share-landing-new/bg.png') no-repeat no-repeat;
    background-size: 100% 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-name {
      color: #a34922;
      font-size: 0.36rem;
      font-weight: 600;
      line-height: 1rem;
      text-align: center;
      width: 4.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-title {
      height: 1.9rem;
    }
    &-container {
      height: 7rem;
      width: 100%;
    }
    &-discuss {
      background: url('~@/assets/images/share-landing-new/discuss-bg.png') no-repeat no-repeat;
      background-size: 100% 100%;
      width: 100%;
      height: 6.5rem;
      display: flex;
      justify-content: center;
      &-content {
        margin-top: 1rem;
        background-color: #fff;
        height: 5rem;
        width: 6.9rem;
        border-radius: 0.18rem;
        display: flex;
        flex-direction: column;
        &-title {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #aa5509;
          font-size: 0.34rem;
          font-weight: 600;
          height: 1rem;
          width: 100%;
          &-img__icon {
            width: 0.66rem;
            height: 0.36rem;
            margin: 0 0.2rem;
          }
        }
        &-warp {
          height: 4rem;
          overflow: hidden;
          padding: 0 0.4rem;
          &-item {
            position: relative;
            display: flex;
            align-items: center;
            margin: 0.3rem 0;
            &.item-transition {
              transition: top 1s;
            }
            &-info {
              padding: 0 0.2rem;
              color: #666666;
              font-size: 0.28rem;
              font-weight: 600;
              border-radius: 0.1rem;
              margin-left: 0.5rem;
              height: 0.8rem;
              display: flex;
              align-items: center;
              position: relative;
              &-cont {
                display: inline-block;
                max-width: 4.3rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              &.ffe0c9 {
                background-color: #ffe0c9;
              }
              &.ffdfe2 {
                background-color: #ffdfe2;
              }
              &-icon {
                display: block;
                position: absolute;
                left: -0.1rem;
                top: 0.4rem;
                transform: translateY(-0.05rem);
                width: 0;
                height: 0;
                border-top: 0.1rem solid transparent;

                border-bottom: 0.1rem solid transparent;
                &.ffe0c9 {
                  border-right: 0.1rem solid #ffe0c9;
                }
                &.ffdfe2 {
                  border-right: 0.1rem solid #ffdfe2;
                }
              }
            }
            &-img__author {
              width: 0.8rem;
              height: 0.8rem;
              border-radius: 50%;
            }
          }
        }
      }
    }
    &-btn {
      width: 6.44rem;
      height: 1.1rem;
      border-radius: 0.55rem;
      background: linear-gradient(180deg, #ffc9bb 0%, #ff8463 13%, #fd124d 93%, #ff5c85 100%);
      box-shadow: 0 0.07rem 0 0 #db4141;
      font-size: 0.44rem;
      color: #fff;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0.5rem 0;
      animation: downloadAni 1.5s infinite linear;
      position: fixed;
      bottom: 0;
    }
    &-btn-warp {
      background-color: #ffdfe2;
      height: 2rem;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 1s;
  }
  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }
  @keyframes downloadAni {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.95);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
